import React, { useContext } from "react";
import { BASE_API_ENDPOINT } from "../../utils/API";
import { DataContext } from "../../utils";

const Projects = () => {

  const { projects } = useContext(DataContext);

  return (
    <section className="text-gray-600 body-font" id="ourprojects">
      <div className="container px-5 py-24 mx-auto">
        <div className="flex flex-wrap w-full mb-20">
          <div className="lg:w-1/2 w-full mb-6 lg:mb-0">
            <h1 className="sm:text-3xl text-2xl text-center lg:text-left font-medium title-font mb-2 text-gray-900">
              Our Projects
            </h1>
            <div className="h-1 w-20 bg-secondary invisible lg:visible rounded"></div>
          </div>
          <p className="lg:w-1/2 w-full text-center lg:text-left leading-relaxed text-gray-500">
            Featured here are some projects that the neoCedrus team is working
            on or providing continuous support and maintenance.
          </p>
        </div>
        <div className="flex flex-wrap -m-4">
          {projects &&
            projects.map((item, index) => (
              item?.subtitle && item?.thumbnail?.url && item?.name && item?.description &&
              <div className="xl:w-1/4 md:w-1/2 p-4" key={index}>
                <h3 className="text-gray-900 text-xs font-semibold title-font rounded-md box-border border bg-gray-200 py-1 px-2 mb-3 inline-flex">
                  {item.subtitle}
                </h3>
                <div className="bg-gray-100 hover:bg-gray-200 p-6 rounded-lg">
                  <a href={item.navigation} target="_blank" rel="noreferrer">
                    <img
                      className="h-40 rounded w-full object-center mb-2 object-cover"
                      src={`${BASE_API_ENDPOINT}${item.thumbnail.url}`}
                      alt="content"
                    />
                    <h2 className="text-lg text-gray-900 font-medium title-font mb-4">
                      {item.name}
                    </h2>
                    <p className="leading-relaxed text-sm">{item.description}</p>
                  </a>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default Projects;

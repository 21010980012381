import axios from 'axios';
import { APP_URL } from '../environment';

export const BASE_API_ENDPOINT = APP_URL;

const API = axios.create({
  baseURL: BASE_API_ENDPOINT,
});


export default API;

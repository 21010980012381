import React, { useContext } from "react";
import Counter from "../../components/Counter";
import {
  TrashIcon,
  UserGroupIcon,
  ChipIcon,
  CodeIcon,
} from "@heroicons/react/outline";
import { DataContext } from "../../utils/DataProvider";

const Stats = () => {
  const { team, projects } = useContext(DataContext);
  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 py-24 mx-auto">
        <div className="flex flex-col text-center w-full mb-20">
          <p className="lg:w-2/3 mx-auto leading-relaxed text-base">Key Metrics</p>
          <h1 className="sm:text-4xl text-3xl font-bold title-font mb-4 text-gray-900">
            Numbers that make us proud! for the most part...
          </h1>
        </div>
        <div className="flex flex-wrap -m-4 text-center">
          <div className="p-4 md:w-1/4 sm:w-1/2 w-full">
            <div className="border-2 border-gray-200 px-4 py-6 rounded-lg">
              <TrashIcon
                height={12}
                width={12}
                className="text-primary w-12 h-12 mb-3 inline-block"
              />
              <Counter number={100} stat="braincells" />
              <p className="leading-relaxed">Bad Jokes Per week</p>
            </div>
          </div>
          <div className="p-4 md:w-1/4 sm:w-1/2 w-full">
            <div className="border-2 border-gray-200 px-4 py-6 rounded-lg">
              <UserGroupIcon
                height={12}
                width={12}
                className="text-primary w-12 h-12 mb-3 inline-block"
              />
              <Counter number={team && team.length} stat="members" />
              <p className="leading-relaxed">Team Members</p>
            </div>
          </div>
          <div className="p-4 md:w-1/4 sm:w-1/2 w-full">
            <div className="border-2 border-gray-200 px-4 py-6 rounded-lg">
              <ChipIcon
                height={12}
                width={12}
                className="text-primary w-12 h-12 mb-3 inline-block"
              />
              <Counter number={projects && projects.length} stat="projects" />
              <p className="leading-relaxed">Projects</p>
            </div>
          </div>
          <div className="p-4 md:w-1/4 sm:w-1/2 w-full">
            <div className="border-2 border-gray-200 px-4 py-6 rounded-lg">
              <CodeIcon
                height={12}
                width={12}
                className="text-primary w-12 h-12 mb-3 inline-block"
              />
              <Counter number={3500} stat="commits" />
              <p className="leading-relaxed">Commits</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Stats;

import React, { useEffect } from "react";
import { useState } from "react";
import { createContext } from "react";
import API from "./API";

export const DataContext = createContext({
  team: null,
  projects: null,
  teamCount: null,
  projectsCount: null,
});

export const DataProvider = ({ children }) => {
  
  const [team, setTeam] = useState();
  const [projects, setProjects] = useState();

  useEffect(() => {
    API.get("members")
      .then((response) => {
        setTeam(response.data);
      })
      .catch((err) => {
        console.error(err)
      });
  }, []);

  useEffect(() => {
    API.get("projects")
      .then((response) => {
        setProjects(response.data);
      })
      .catch((err) => {
        console.error(err)
      });
  }, []);

  return (
    <DataContext.Provider
      value={{
        team,
        projects,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

import React from "react";

import team from "../../assets/images/team_photo_4.jpg";

const About = () => {
  return (
    <section className="container mx-auto text-gray-600 body-font">
      <div className="relative overflow-hidden">
        <div className="relative pb-8 sm:pb-16 md:pb-20 lg:w-full lg:max-w-max lg:pb-28 xl:pb-32">
          <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
            <div className="text-center xl:text-left">
              <h1 className="tracking-tight font-extrabold text-gray-900 text-4xl sm:text-5xl md:text-6xl xl:w-2/5 xl:text-5xl">
                Creating things that are{" "}
                <span className="text-secondary block sm:inline">
                  simple and effective
                </span>
              </h1>
              <p className="mt-3 text-gray-500 sm:mt-5 sm:text-lg sm:mx-auto md:mt-5 md:text-xl md:w-full xl:w-2/4 lg:mx-0">
                neoCedrus is a student-led unit under the Center for Learning
                and Excellence that specializes in the development, deployment,
                and maintenance of IT solutions at AUI. It empowers students
                from different majors to develop production-ready projects for
                AUI and other stakeholders.
              </p>
            </div>
          </main>
        </div>
        <div className="xl:absolute p-10 lg:inset-y-0 lg:right-0 xl:w-1/2">
          <img
            className="h-56 w-full object-cover sm:h-72 md:h-96 lg:h-full shadow-xl rounded-3xl"
            src={team}
            alt="team_pic"
          />
        </div>
      </div>
    </section>
  );
};

export default About;

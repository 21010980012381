import React from "react";
import you from '../../assets/images/you.jpg'

const BlogSection = () => {
    const articles = [
        {category: 'Version Control', name: "Configuring EsLint into Github Actions", description: 'Create github actions with code quality in mind using yml config files and eslint!', author: 'Ziyad Mourabiti', profile: you, date: '20 Mars 2021', minutes: '6 min'},
        {category: 'Version Control', name: "Configuring EsLint into Github Actions", description: 'Create github actions with code quality in mind using yml config files and eslint!', author: 'Ziyad Mourabiti', profile: you, date: '20 Mars 2021', minutes: '6 min'},
        {category: 'Version Control', name: "Configuring EsLint into Github Actions", description: 'Create github actions with code quality in mind using yml config files and eslint!', author: 'Ziyad Mourabiti', profile: you, date: '20 Mars 2021', minutes: '6 min'},
        {category: 'Version Control', name: "Configuring EsLint into Github Actions", description: 'Create github actions with code quality in mind using yml config files and eslint!', author: 'Ziyad Mourabiti', profile: you, date: '20 Mars 2021', minutes: '6 min'}
    ]
  return (
    <section className="container mx-auto p-12 mb-10">
      <div className="header flex items-end justify-between mb-12">
        <div className="flex flex-col text-center w-full mb-5">
          <p className="text-4xl font-bold text-gray-800 mb-4">Lastest articles</p>
          <p className="text-2xl font-light text-gray-400">
            All article are verified by 2 experts and valdiate by the CTO
          </p>
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-5">
        {articles.map((item, index) => (
        <div className="overflow-hidden shadow-lg rounded-lg h-90 w-100 md:w-80 lg:w-auto cursor-pointer box-border" key={index}>
          <a href="#" className="w-full block h-full">
            <img
              alt="blog photo"
              src={you}
              className="max-h-40 w-full object-cover"
            />
            <div className="bg-white dark:bg-gray-800 w-full p-4">
              <p className="text-indigo-500 text-md font-medium">{item.category}</p>
              <p className="text-gray-800 dark:text-white text-xl font-medium mb-2">
                Work at home
              </p>
              <p className="text-gray-400 dark:text-gray-300 font-light text-md">
                {item.description}
              </p>
              <div className="flex items-center mt-4">
                <a href="#" className="block relative">
                  <img
                    alt="profil"
                    src={you}
                    className="mx-auto object-cover rounded-full h-10 w-10 "
                  />
                </a>
                <div className="flex flex-col justify-between ml-4 text-sm">
                  <p className="text-gray-800 dark:text-white">{item.author}</p>
                  <p className="text-gray-400 dark:text-gray-300">
                    {item.date} - {item.minutes} read
                  </p>
                </div>
              </div>
            </div>
          </a>
        </div>
        )) 
        }
      </div>
    </section>
  );
};

export default BlogSection
import React from "react";
import neocedruslogo from "../../assets/images/neocedrus.png";
import githublogo from "../../assets/images/github.svg";
import buglogo from "../../assets/images/bug.svg";


const Footer = () => {
  return (
    <footer className="text-gray-600 body-font">
      <div className="bg-gray-100">
        <div className="container px-5 py-6 mx-auto flex items-center sm:flex-row flex-col">
          <a href="#" className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
            <img
              className="h-8 w-auto sm:h-10 rounded-md"
              src={neocedruslogo}
              alt=""
            />
            <span className="ml-3 text-xl">neoCedrus</span>
          </a>
          <p className="text-sm text-gray-500 sm:ml-6 sm:mt-0 mt-4">
            © 2024 neoCedrus — Al Akhawayn University in Ifrane
          </p>
          <span className="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
            <a
              href="https://www.linkedin.com/company/neocedrus/"
              target="_blank"
              className="ml-3 text-gray-500"
              rel="noreferrer"
            >
              <svg
                fill="black"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="0"
                className="w-5 h-5"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="none"
                  d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"
                ></path>
                <circle cx="4" cy="4" r="2" stroke="none"></circle>
              </svg>
            </a>
            <a
              href="https://github.com/neocedrus"
              target="_blank"
              className="ml-3 text-gray-500"
              rel="noreferrer"
            >
              <img className="w-5 h-5" src={githublogo} alt="github" />
            </a>
            <a
              href="https://neocedrus.statuspage.io"
              target="_blank"
              className="ml-3 text-gray-500"
              rel="noreferrer"
            >
              <img className="w-5 h-5" src={buglogo} alt="statuspage" />
            </a>
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

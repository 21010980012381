import React, { useEffect } from "react";
import { animatedNumber } from "./Counter.module.css";

const Counter = ({ number, stat }) => {

    useEffect(() => {
        document.getElementById(stat).style.setProperty('--max', number)
    }, [number])

    return (
        <div className="font-medium text-3xl text-gray-900">
            <div className={animatedNumber} id={stat}></div>
        </div>
    )
};
export default Counter;

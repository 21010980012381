import { ExternalLinkIcon } from "@heroicons/react/outline";
import React, { useContext } from "react";
import you from "../../assets/images/you.jpg";
import { BASE_API_ENDPOINT } from "../../utils/API";
import { DataContext } from "../../utils";
import { Link } from "gatsby";

const Team = () => {
  const { team } = useContext(DataContext);

  return (
    <>
      <section className="text-gray-600 body-font md:-mt-24" id="team">
        <div className="container px-5 py-24 mx-auto">
          <div className="flex flex-col text-center w-full mb-20">
            <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">
              Our Team
            </h1>
            <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
              We are a team full of passionate students (and we're growing!)
            </p>
          </div>
          <div className="flex flex-wrap -m-2">
            {team &&
              team.map(
                (item, index) =>
                  item.profile &&
                  item.role &&
                  item.name && (
                    <div key={index} className="p-2 lg:w-1/3 md:w-1/2 w-full">
                      <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
                        <img
                          alt="team"
                          className="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4"
                          src={`${BASE_API_ENDPOINT}${item.profile.url}`}
                        />
                        <div className="flex-grow">
                          <h2 className="text-gray-900 title-font font-medium">
                            {item.name}
                          </h2>
                          <p className="text-gray-500">{item.role}</p>
                        </div>
                      </div>
                    </div>
                  )
              )}
            <div className="p-2 lg:w-1/3 md:w-1/2 w-full">
              <Link to="apply">
                <div className="h-full flex items-center bg-gray-100 border border-gray-100 p-4 rounded-lg hover:bg-gray-200">
                  <img
                    alt="team"
                    className="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4"
                    src={you}
                  />
                  <div className="flex justify-between">
                    <h2 className="text-gray-900 title-font font-medium">
                      You could join us too!
                    </h2>
                    <ExternalLinkIcon
                      className="pl-1 flex-shrink-0 h-6 w-6 opacity-40 text-primary"
                      aria-hidden="true"
                    />
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Team;

import React from "react";
import neocedruslogo from "../../assets/images/neocedrus.png";

const Hero = () => {
  return (
    <section className="text-gray-600 body-font md:mb-32" id="hero">
      <div className="container flex px-5 pt-20 md:pt-44 md:flex-row flex-col items-center m-auto">
        <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
          <div className="md:hidden lg:max-w-lg lg:w-full w-2/4 md:w-1/3 mx-auto mb-5">
            <img
              className="object-cover object-center rounded-full shadow-2xl"
              alt="hero"
              src={neocedruslogo}
            />
          </div>
          <h1 className="font-black lg:text-8xl md:text-6xl text-6xl mb-4 text-gray-900">
            neoCedrus
          </h1>
          <p className="mb-8 text-xl xl:text-2xl lg:w-2/3 leading-relaxed">
            A team of college students who don't shy away from{" "}
            <code className="box-border bg-gray-100 hover:bg-gray-200 py-1 px-2 rounded-md">
              git push origin -U master --force{" "}
            </code>
          </p>
          <div className="flex justify-center">
            <span>
              We also talk about groundbreaking topics!{" "}
            </span>
          </div>
          <a
            href="#about"
            className="whitespace-nowrap mt-3 inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-lg font-medium text-white bg-primary hover:bg-secondary hover:text-gray-900"
          >
            Who we are
          </a>
        </div>
        <div className="hidden md:block lg:max-w-lg lg:w-full w-2/4 md:w-1/3 mx-auto">
          <img
            className="object-cover object-center rounded-full shadow-2xl"
            alt="hero"
            src={neocedruslogo}
          />
        </div>
      </div>
      <span id="about"></span>
    </section>
  );
};

export default Hero;
